import { User } from "../../../types/User";
import { Box, Button, FormControl, Grid, IconButton, TextField, MenuItem, Select, InputLabel, SelectChangeEvent, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { DataGrid, GridColDef, GridRowsProp, GridSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { useCreateLicenseMutation, useRenewLicenseMutation, useResetLicenseMutation } from "../../licenses/licenseSlice";
import { useState, useEffect } from "react"
import { CreateLicenseRequest, License, RenewLicenseRequest, ResetLicenseRequest } from "../../../types/License";
import { useSnackbar } from "notistack";

type Props = {
    user: User;
}

export function UserLicenseForm({ user }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const [renewLicense, statusRenew] = useRenewLicenseMutation()
    const [resetLicense, statusReset] = useResetLicenseMutation()
    const [createLicense, statusCreate] = useCreateLicenseMutation()
    const [createLicenseReqState, setCreateLicenseReqState] = useState<CreateLicenseRequest>({
        license_type: "MONTHLY",
        quantity: 1,
    })

    async function handleCreateLicense(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const response = await createLicense({ userId: user.id, requestBody: createLicenseReqState });

        if ("data" in response) {
            const licenseIds = response.data.licenses.map((license: any) => license.id).join("\n\n");

            // Copia para a área de transferência
            navigator.clipboard.writeText(licenseIds)
                .then(() => {
                    enqueueSnackbar("IDs das licenças copiadas para a área de transferência!", { variant: "success" });
                })
                .catch(() => {
                    enqueueSnackbar("Erro ao copiar IDs para a área de transferência", { variant: "error" });
                });
        }
    }

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const handleCopyLicenses = () => {
        // Filtra as linhas que estão selecionadas
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));

        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIdsString = selectedRows.map((row) => row.id).join("\n\n");

        navigator.clipboard.writeText(licenseIdsString)
            .then(() => {
                enqueueSnackbar("IDs das licenças copiadas para a área de transferência!", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("Erro ao copiar IDs para a área de transferência", { variant: "error" });
            });
    };

    async function handleResetLicenses() {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        const requestBody: ResetLicenseRequest = {
            licenses: licenseIds
        }

        await resetLicense(requestBody);
    }

    async function handleRenewLicenses() {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        const requestBody: RenewLicenseRequest = {
            licenses: licenseIds
        }

        await renewLicense(requestBody);
    }

    useEffect(() => {
        if (statusRenew.isSuccess) {
            enqueueSnackbar("Renovação de chave efetuada com sucesso!", { variant: "success" });
        }

        if (statusRenew.isError) {
            enqueueSnackbar("Erro ao renovar chave", { variant: "error" });
        }

    }, [enqueueSnackbar, statusRenew.isSuccess, statusRenew.isError]);

    useEffect(() => {
        if (statusCreate.isSuccess) {
            enqueueSnackbar("Chave criada com sucesso!", { variant: "success" });
        }

        if (statusCreate.isError) {
            enqueueSnackbar("Erro ao criar chave", { variant: "error" });
        }
    }, [enqueueSnackbar, statusCreate.isSuccess, statusCreate.isError]);

    useEffect(() => {
        if (statusReset.isSuccess) {
            enqueueSnackbar("Chave resetada com sucesso!", { variant: "success" });
        }

        if (statusReset.isError) {
            enqueueSnackbar("Erro ao resetar chave", { variant: "error" });
        }
    }, [enqueueSnackbar, statusReset.isSuccess, statusReset.isError]);

    const handleGenericChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        setState: React.Dispatch<React.SetStateAction<any>>
    ) => {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNumericChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        setState: React.Dispatch<React.SetStateAction<any>>
    ) => {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: Number(value), // Converte o valor para número
        }));
    };

    const isInvalidDate = (date: string | Date | null) => {
        if (!date) return true;
        const parsedDate = typeof date === "string" ? new Date(date) : date;
        return parsedDate.getTime() === new Date("0001-01-01T00:00:00Z").getTime();
    };

    function mapLicensesToGridRows(licenses: License[]) {
        return licenses.map(license => ({
            id: license.id,
            hwid: license.hwid,
            license_type: license.license_type,
            license_status: license.license_status,
            value: license.value,
            created_at: new Date(license.created_at).toLocaleString(),
            expiration_date: isInvalidDate(license.expiration_date) ? "Pendente Ativação" : new Date(license.expiration_date).toLocaleString(),
            active: license.active,
        }));
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Chave',
            flex: 1,
        },
        {
            field: 'license_type',
            headerName: 'Tipo',
            flex: 1,
            hide: true,
        },
        {
            field: 'created_at',
            headerName: 'Data Criação',
            flex: 1
        },
        {
            field: 'expiration_date',
            headerName: 'Data Expiração',
            flex: 1
        },
        {
            field: 'active',
            headerName: 'Ativa',
            flex: 1,
            hide: true,
        },
    ]


    const rows: GridRowsProp = user?.licenses ? mapLicensesToGridRows(user.licenses) : [];
    const rowCount = user?.licenses?.length || 0;

    // CONFIRM DIALOG
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState("");
    const handleClickOpen = (selectedAction: string) => {
        setAction(selectedAction);
        setOpen(true);
    };

    const handleClose = async (confirmed: boolean) => {
        setOpen(false);
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        if (confirmed) {
            if (action === "renovar") {
                await handleRenewLicenses()
            } else if (action === "resetar") {
                await handleResetLicenses()
            }
        }
    };

    return (
        <Box p={2}>
            <Dialog open={open} onClose={() => handleClose(false)}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Tem certeza de que deseja ${action === "renovar" ? "renovar" : "resetar"} as licenças selecionadas?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleClose(true)} color="secondary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} mt={4} >
                <Box pb={2} pt={2}>
                    <Box mb={2}>
                        <Typography variant="h4">Criar Chaves</Typography>
                    </Box>
                </Box>

                <form onSubmit={handleCreateLicense}>
                    <Grid container spacing={3} mb={6} >
                        <Grid item xs={12} lg={3} >
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    name="quantity"
                                    label="Quantidade Chaves"
                                    type={"number"}
                                    value={createLicenseReqState.quantity}
                                    disabled={false}
                                    onChange={(e) => handleNumericChange(e, setCreateLicenseReqState)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3} >
                            <FormControl fullWidth>
                                <InputLabel id="license_type">Tipo de Chave</InputLabel>
                                <Select
                                    name="license_type"
                                    labelId="license_type"
                                    id="license_type"
                                    value={createLicenseReqState.license_type || ""}
                                    disabled={false}
                                    label="Tipo de Usuário"
                                    onChange={(e) => handleGenericChange(e, setCreateLicenseReqState)}
                                >
                                    <MenuItem value={"MONTHLY"}>Mensal</MenuItem>
                                    <MenuItem value={"DAILY"}>Diária</MenuItem>
                                    <MenuItem value={"TEMP"}>Temporária</MenuItem>
                                    <MenuItem value={"LIFETIME"}>Vitalício</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}  >
                            <Box display={"flex"} gap={2}>
                                <Button type="submit" variant="contained" color="secondary" disabled={statusCreate.isLoading}>
                                    Criar Chave
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>

                <Grid item xs={12} marginBottom={2} container justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h4">Chaves do Usuário</Typography>
                    </Box>

                    <Box display="flex" gap={2}>
                        <Button type="submit" variant="outlined" onClick={handleCopyLicenses} color="primary">
                            copiar
                        </Button>
                        <Button type="submit" variant="contained" onClick={() => handleClickOpen("renovar")} color="primary" disabled={statusRenew.isLoading}>
                            Renovar
                        </Button>
                        <Button type="submit" variant="contained" onClick={() => handleClickOpen("resetar")} color="secondary" disabled={statusReset.isLoading}>
                            Resetar
                        </Button>
                    </Box>
                </Grid>

                <Box sx={{ display: "flex", minHeight: "400px", overflowY: "auto" }}>
                    <DataGrid
                        autoHeight
                        rows={rows}
                        columns={columns}
                        checkboxSelection={true}
                        disableColumnFilter={true}
                        disableColumnSelector={false}
                        disableDensitySelector={true}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                exportButton: true,
                                exportButtonProps: {
                                    csvFileName: `${user?.name || "User"}-${new Date()}.csv`,
                                },
                            }
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }], // Ordena por created_at em ordem decrescente
                            },
                        }}
                        onSelectionModelChange={(newSelection) => {
                            setSelectionModel(newSelection); // Atualiza o modelo de seleção
                        }}
                    />
                </Box>
            </Grid>
        </Box>
    )
}