import { Box, Paper, Typography, SelectChangeEvent, Button, Grid, FormControl, TextField, InputLabel, Select, MenuItem, IconButton } from "@mui/material"
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { UserLicensePrice } from "../../types/User";
import { useCreateUserLicensePriceMutation, useDeleteUserLicensePriceMutation, useGetUserLicenseLicensePricesQuery } from "./userSlice";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

export const UserLicensesPrice = () => {
    const id = useParams().id || "";
    const { data, isFetching } = useGetUserLicenseLicensePricesQuery(id);
    const [createUserLicensePrice, statusCreateUserLicensePrice] = useCreateUserLicensePriceMutation();
    const [deleteUserLicensePrice, statusDeleteUserLicensePrice] = useDeleteUserLicensePriceMutation();

    const [userLicensePriceState, setUserLicensePriceState] = useState<UserLicensePrice>({
        id: 0,
        user_id: id,
        price_type: "",
        license_type: "",
        license_value: 0,
    });

    const { enqueueSnackbar } = useSnackbar();

    async function handleCreate(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await createUserLicensePrice(userLicensePriceState);
    }

    async function handleDelete(id: number) {
        await deleteUserLicensePrice(id);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserLicensePriceState({ ...userLicensePriceState, [name]: value });
    };

    const handleNumericChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        setState: React.Dispatch<React.SetStateAction<any>>
    ) => {
        const { name, value } = e.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: Number(value), // Converte o valor para número
        }));
    };

    const handleChangeUserType = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        console.log(name, value);
        setUserLicensePriceState((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (statusCreateUserLicensePrice.isSuccess) {
            enqueueSnackbar("Preço criado com sucesso!", { variant: "success" });
        }

        if (statusCreateUserLicensePrice.isError) {
            enqueueSnackbar("Erro ao criar preço!", { variant: "error" });
        }
    }, [enqueueSnackbar, statusCreateUserLicensePrice.isError, statusCreateUserLicensePrice.isSuccess])


    useEffect(() => {
        if (statusDeleteUserLicensePrice.isSuccess) {
            enqueueSnackbar("Preço deletado com sucesso!", { variant: "success" });
        }

        if (statusDeleteUserLicensePrice.isError) {
            enqueueSnackbar("Erro ao deletar preço!", { variant: "error" });
        }
    }, [enqueueSnackbar, statusDeleteUserLicensePrice.isError, statusDeleteUserLicensePrice.isSuccess])


    const userLogado = useAppSelector((state: RootState) => state.user.user);

    function mapLicensesToGridRows(prices: UserLicensePrice[]) {
        return prices.map(price => ({
            id: price.id,
            license_type: price.license_type,
            price_type: price.price_type,
            license_value: price.license_value,
        }));
    }

    const columns: GridColDef[] = [
        {
            field: 'license_value',
            headerName: 'Valor',
            flex: 1,
        },
        {
            field: 'price_type',
            headerName: 'Tipo Preço',
            flex: 1,
        },
        {
            field: 'license_type',
            headerName: 'Tipo Licença',
            flex: 1
        },
        {
            field: 'id',
            headerName: '',
            type: "number",
            flex: 1,
            align: "center",
            renderCell: renderActionsCell,
        },
    ]


    const rows: GridRowsProp = data?.prices ? mapLicensesToGridRows(data.prices) : [];
    const rowCount = data?.prices?.length || 0;

    function renderActionsCell(params: GridRenderCellParams) {
        return (
            <IconButton
                color={"secondary"}
                onClick={() => handleDelete(params.value)}
                aria-label="delete"
            >
                <DeleteIcon />
            </IconButton>
        )
    }

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="h4">Atualizar Cliente</Typography>
                    </Box>
                </Box>
                <Box p={2}>
                    <form onSubmit={handleCreate}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="license_value"
                                        type={"number"}
                                        label="Valor"
                                        value={userLicensePriceState?.license_value}
                                        disabled={statusCreateUserLicensePrice.isLoading}
                                        onChange={(e) => handleNumericChange(e, setUserLicensePriceState)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl fullWidth>
                                    <InputLabel id="price_type">Tipo de Preço</InputLabel>
                                    <Select
                                        name="price_type"
                                        labelId="price_type"
                                        id="price_type"
                                        value={userLicensePriceState?.price_type || ""}
                                        // disabled={isDisabled}
                                        label="Tipo de Preço"
                                        onChange={(e) => handleChangeUserType(e)}
                                    >
                                        <MenuItem value={"ACQUISITION"}>Aquisição</MenuItem>
                                        <MenuItem value={"RENOVATION"}>Renovação</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl fullWidth>
                                    <InputLabel id="license_type">Tipo de Licença</InputLabel>
                                    <Select
                                        name="license_type"
                                        labelId="license_type"
                                        id="license_type"
                                        value={userLicensePriceState?.license_type || ""}
                                        // disabled={isDisabled}
                                        label="Tipo de Chave"
                                        onChange={(e) => handleChangeUserType(e)}
                                    >
                                        <MenuItem value={"MONTHLY"}>Mensal</MenuItem>
                                        <MenuItem value={"DAILY"}>Diária</MenuItem>
                                        <MenuItem value={"LIFETIME"}>Vitalício</MenuItem>
                                        <MenuItem value={"TEMP"}>Temporária</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} >
                                <Box display={"flex"} gap={2}>
                                    <Button variant="contained" component={Link} to={`/users/edit/${id}`}>
                                        Voltar
                                    </Button>

                                    <Button type="submit" variant="contained" color="secondary" disabled={statusCreateUserLicensePrice.isLoading}>
                                        Salvar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>

                <Box sx={{ display: "flex", minHeight: "400px", overflowY: "auto" }}>
                    <DataGrid
                        loading={isFetching}
                        autoHeight
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        disableColumnFilter={true}
                        disableSelectionOnClick={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            }
                        }}
                    />
                </Box>
            </Paper>
        </Box>
    );
}