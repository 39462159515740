import { Box, Button, Typography, Grid, MenuItem, FormControl, Select, InputLabel, SelectChangeEvent, DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions } from "@mui/material"
import { Link } from "react-router-dom"
import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridRowsProp, GridSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { useGetLicensesQuery, useRenewLicenseMutation, useResetLicenseMutation } from "./licenseSlice";
import { useState, useEffect } from 'react'
import { useSnackbar } from "notistack";
import { ListLicense, RenewLicenseRequest, ResetLicenseRequest } from "../../types/License";
import { CreatePaymentRequest } from "../../types/Payment";
import { useCreatePaymentMutation } from "../payments/paymentSlice";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";


export const LicenseList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [renewLicense, statusRenew] = useRenewLicenseMutation()
    const [resetLicense, statusReset] = useResetLicenseMutation()
    const [createPayment, statusCreatePayment] = useCreatePaymentMutation()

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const handleCopyLicenses = () => {
        // Filtra as linhas que estão selecionadas
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));

        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIdsString = selectedRows.map((row) => row.id).join("\n\n");

        navigator.clipboard.writeText(licenseIdsString)
            .then(() => {
                enqueueSnackbar("IDs das licenças copiadas para a área de transferência!", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("Erro ao copiar IDs para a área de transferência", { variant: "error" });
            });
    };

    async function handleResetLicenses() {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        const requestBody: ResetLicenseRequest = {
            licenses: licenseIds
        }

        await resetLicense(requestBody);
    }

    async function handlePay() {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        const requestBody: CreatePaymentRequest = {
            licenses: licenseIds
        }

        await createPayment(requestBody)
    }

    async function handleRenewLicenses() {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        const requestBody: RenewLicenseRequest = {
            licenses: licenseIds
        }

        await renewLicense(requestBody);
    }

    useEffect(() => {
        if (statusRenew.isSuccess) {
            enqueueSnackbar("Renovação de chave efetuada com sucesso!", { variant: "success" });
        }

        if (statusRenew.isError) {
            enqueueSnackbar("Erro ao renovar chave", { variant: "error" });
        }
    }, [enqueueSnackbar, statusRenew.isSuccess, statusRenew.isError]);

    useEffect(() => {
        if (statusCreatePayment.isSuccess) {
            enqueueSnackbar("Pagamento criado com sucesso!", { variant: "success" });
        }

        if (statusCreatePayment.isError) {
            enqueueSnackbar("Erro ao criar pagamento", { variant: "error" });
        }
    }, [enqueueSnackbar, statusCreatePayment.isSuccess, statusCreatePayment.isError]);

    useEffect(() => {
        if (statusReset.isSuccess) {
            enqueueSnackbar("Chave resetada com sucesso!", { variant: "success" });
        }

        if (statusReset.isError) {
            enqueueSnackbar("Erro ao resetar chave", { variant: "error" });
        }
    }, [enqueueSnackbar, statusReset.isSuccess, statusReset.isError]);

    const [options, setOptions] = useState({
        page: 1,
        filter: "",
        limit: 10,
        rowsPerPage: [10, 20, 30],
        license_status: "TODOS",
    });

    const { data, isFetching, error } = useGetLicensesQuery(options)

    function handleOnPageChange(page: number) {
        setOptions({ ...options, page: page + 1 });
    }

    function handleOnPageSizeChange(limit: number) {
        setOptions({ ...options, limit });
    }

    function handleFilterChange(filterModel: GridFilterModel) {
        if (!filterModel.quickFilterValues?.length) {
            return setOptions({ ...options, filter: "" });
        }

        const filter = filterModel.quickFilterValues.join("");
        setOptions({ ...options, filter });
    }

    const handleChangeLicenseStatus = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setOptions((prev_options) => ({
            ...prev_options,
            [name]: value, // Atualiza user.user_type
        }));
    };

    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
        }
    }

    const isInvalidDate = (date: string | Date | null) => {
        if (!date) return true;
        const parsedDate = typeof date === "string" ? new Date(date) : date;
        return parsedDate.getTime() === new Date("0001-01-01T00:00:00Z").getTime();
    };
    function mapLicensesToGridRows(licenses: ListLicense[]) {
        return licenses.map(license => ({
            id: license.id,
            hwid: license.hwid,
            owner_id: license.owner.owner_id,
            owner_name: license.owner.owner_name,
            owner_cellphone: license.owner.owner_cellphone,
            created_by_name: license.created_by.name,
            license_type: license.license_type,
            license_status: license.license_status,
            value: license.value,
            created_at: new Date(license.created_at).toLocaleString(),
            expiration_date: isInvalidDate(license.expiration_date) ? "Pendente Ativação" : new Date(license.expiration_date).toLocaleString(),
            active: license.active,
        }));
    }

    function renderOwnerNameCell(rowData: GridRenderCellParams) {
        const ownerId = rowData.row.owner_id;

        return (
            <Link
                style={{ textDecoration: "none" }}
                to={`/users/edit/${ownerId}`}
            >
                <Typography color="primary">{rowData.value}</Typography>
            </Link>
        )
    }

    const columns: GridColDef[] = [
        {
            field: 'owner_name',
            headerName: 'Cliente',
            flex: 1,
            minWidth: 100,
            renderCell: renderOwnerNameCell,
        },
        {
            field: 'owner_cellphone',
            headerName: 'Celular',
            flex: 1,            
        },
        {
            field: 'id',
            headerName: 'Chave',
            flex: 2,
            minWidth: 200,
        },        
        {
            field: 'license_type',
            headerName: 'Tipo',
            flex: 1,
            hide: true,
        },
        {
            field: 'created_at',
            headerName: 'Data Criação',
            flex: 1,
            hide: true,
        },        
        {
            field: 'active',
            headerName: 'Ativa',
            flex: 1,
            hide: true,
        },
    ]
    const userLogado = useAppSelector((state: RootState) => state.user.user);
    if (userLogado?.user_type === "ADMIN") {
        columns.push(
            {
                field: 'hwid',
                headerName: 'Computador',
                flex: 1,
            },
            {
                field: 'created_by_name',
                headerName: 'Criado Por',
                flex: 1,
                minWidth: 100,
            },
        );
    }

    columns.push(        
        {
            field: 'expiration_date',
            headerName: 'Data Expiração',
            flex: 1
        },
    );

    const rows: GridRowsProp = data?.licenses ? mapLicensesToGridRows(data.licenses) : [];
    const rowCount = data?.total || 0;

    // CONFIRM DIALOG
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState("");
    const handleClickOpen = (selectedAction: string) => {
        setAction(selectedAction);
        setOpen(true);
    };

    const handleClose = async (confirmed: boolean) => {
        setOpen(false);
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        if (confirmed) {
            if (action === "pagar") {
                await handlePay()
            } else if (action === "renovar") {
                await handleRenewLicenses()
            } else if (action === "resetar") {
                await handleResetLicenses()
            }
        }
    };

    return (
        <Box maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Dialog open={open} onClose={() => handleClose(false)}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Tem certeza de que deseja ${action === "pagar" ? "pagar" : action === "renovar" ? "renovar" : "resetar"} as licenças selecionadas?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleClose(true)} color="secondary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container alignItems="center" justifyContent="space-between" spacing={2} mb={2} mt={1}>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel id="license_status">Status da Chave</InputLabel>
                        <Select
                            name="license_status"
                            labelId="license_status"
                            id="license_type"
                            value={options.license_status}
                            label="Status da Chave"
                            disabled={false}
                            onChange={handleChangeLicenseStatus}
                            sx={{
                                height: '40px',
                                '.MuiSelect-select': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '8px 14px',
                                },
                            }}
                        >
                            <MenuItem defaultChecked value={"TODOS"}>Todos</MenuItem>
                            <MenuItem value={"PAID"}>Pago</MenuItem>
                            <MenuItem value={"UNPAID"}>Pendente Pagamento</MenuItem>
                            <MenuItem value={"EXPIRING"}>Próximo de Vencer</MenuItem>
                            <MenuItem value={"EXPIRED"}>Vencido</MenuItem>
                            <MenuItem value={"PENDING_APPROVAL"}>Aguardando Aprovação</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md="auto">
                    <Box display="flex" gap={2}>
                        <Button type="button" variant="outlined" onClick={handleCopyLicenses} color="primary">Copiar</Button>
                        <Button type="button" variant="contained" onClick={() => handleClickOpen("renovar")} color="primary" disabled={statusRenew.isLoading}>Renovar</Button>
                        <Button type="button" variant="contained" onClick={() => handleClickOpen("resetar")} color="secondary" disabled={statusReset.isLoading}>Resetar</Button>
                        <Button type="button" variant="contained" onClick={() => handleClickOpen("pagar")} color="success" disabled={statusCreatePayment.isLoading}>Pagar</Button>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ display: "flex", height: 671 }}>
                <DataGrid
                    rows={rows}
                    pagination={true}
                    columns={columns}
                    pageSize={options.limit}
                    filterMode="server"
                    rowCount={rowCount}
                    loading={isFetching}
                    paginationMode="server"
                    checkboxSelection={true}
                    disableColumnFilter={true}
                    disableColumnSelector={false}
                    disableDensitySelector={true}
                    rowsPerPageOptions={options.rowsPerPage}
                    componentsProps={componentProps}
                    onPageChange={handleOnPageChange}
                    components={{ Toolbar: GridToolbar }}
                    onFilterModelChange={handleFilterChange}
                    onPageSizeChange={handleOnPageSizeChange}
                    onSelectionModelChange={(newSelection) => {
                        setSelectionModel(newSelection); // Atualiza o modelo de seleção
                    }}
                />
            </Box>
        </Box>
    );
}