import { ListUsers, User, ListUserParams, UserLicensePrice, ListUserLicensePriceResponse } from "../../types/User";
import { apiSlice } from "../api/apiSlice";

const endpointUrl = "/v1/users"

function parseQueryParams(params: ListUserParams) {
    const query = new URLSearchParams();

    if (params.page) {
        query.append("page", params.page.toString());
    }

    if (params.limit) {
        query.append("limit", params.limit.toString());
    }

    if (params.filter) {
        query.append("filter", params.filter);
    }

    if (params.sort_by) {
        query.append("sort_by", params.sort_by);
    }

    if (params.sort_dir) {
        query.append("sort_dir", params.sort_dir);
    }

    return query.toString();
}

function getUsers({ page = 1, limit = 10, filter = "", sortBy = "created_at", sortDir = "desc" }) {
    const params = { page, limit, filter, sortBy, sortDir };
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createUserMutation(user: User) {
    return {
        url: endpointUrl,
        method: "POST",
        body: user,
    }
}

function updateUserMutation(user: User) {
    return {
        url: `${endpointUrl}/${user.id}`,
        method: "PUT",
        body: user,
    }
}

function getUser({ id }: { id: string }) {
    return `${endpointUrl}/${id}`;
}

function createUserLicensePriceMutation(userLicensePrice: UserLicensePrice) {
    return {
        url: "/v1/users/license-price",
        method: "POST",
        body: userLicensePrice,
    }
}

function deleteUserLicensePriceMutation(userLicensePriceId: number) {
    return {
        url: `/v1/users/license-price/${userLicensePriceId}`,
        method: "DELETE",
    }
}

function getUserLicensePrice(userId: string) {
    return `/v1/users/${userId}/license-price`;
}

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: ({ query, mutation }) => ({
        getUsers: query<ListUsers, ListUserParams>({
            query: getUsers,
            providesTags: ["Users"],
        }),
        getUser: query<User, { id: string }>({
            query: getUser,
            providesTags: ["Users"],
        }),
        createUser: mutation<User, User>({
            query: createUserMutation,
            invalidatesTags: ["Users"],
        }),
        updateUser: mutation<User, User>({
            query: updateUserMutation,
            invalidatesTags: ["Users"],
        }),
        createUserLicensePrice: mutation<UserLicensePrice, UserLicensePrice>({
            query: createUserLicensePriceMutation,
            invalidatesTags: ["UserLicensePrice"],
        }),
        deleteUserLicensePrice: mutation<void, number>({
            query: deleteUserLicensePriceMutation,
            invalidatesTags: ["UserLicensePrice"],
        }),
        getUserLicenseLicensePrices: query<ListUserLicensePriceResponse, string>({
            query: getUserLicensePrice,
            providesTags: ["UserLicensePrice"],
        }),
    })
});


export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetUserQuery,
    useCreateUserLicensePriceMutation,
    useGetUserLicenseLicensePricesQuery,
    useDeleteUserLicensePriceMutation
} = usersApiSlice 
