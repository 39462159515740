import { Box, Paper, Typography, SelectChangeEvent } from "@mui/material"
import { useCreateUserMutation, useGetUserQuery } from "./userSlice";
import { useEffect, useState } from "react";
import { UserForm } from "./components/UserForm";
import { User } from "../../types/User";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export const UserCreate = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [createUser, status] = useCreateUserMutation()
    const [isDisabled, setIsDisabled] = useState(false)

    const [userState, setUserState] = useState<User>({
        id: "",
        username: "",
        name: "",
        email: "",
        active: true,
        cellphone: "",
        user_type: "CUSTOMER",
        created_at: new Date(),
        license_count: 0,
        created_by_name: "",
        licenses: [],
    })

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const response = await createUser(userState);

        // Verifica se a criação foi bem-sucedida e a resposta contém um ID
        if ("data" in response) {
            // Atualiza o estado com o ID retornado
            setUserState((prevUser) => ({
                ...prevUser,
                id: response.data.id, // Supondo que o ID está em response.data.id
            }));

            navigate(`/users/edit/${response.data.id}`);
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserState({ ...userState, [name]: value });
    };
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setUserState({ ...userState, [name]: checked });
    };

    const handleChangeUserType = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setUserState((prevUser) => ({
            ...prevUser,
            [name]: value, // Atualiza user.user_type
        }));
    };


    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("User created successfully", { variant: "success" });
        }

        if (status.isError) {
            enqueueSnackbar("Error creating user", { variant: "error" });
        }
    }, [enqueueSnackbar, status.isSuccess, status.isError]);


    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="h4">Criar Cliente</Typography>
                    </Box>
                </Box>

                <UserForm
                    user={userState}
                    isDisabled={isDisabled}
                    isLoading={false}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleToggle={handleToggle}
                    handleChangeUserType={handleChangeUserType}
                />
            </Paper>
        </Box>
    );
}