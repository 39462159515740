import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridRowsProp, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ListUsers, User } from "../../../types/User";
import { Typography, Box } from '@mui/material'
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

type Props = {
    data: ListUsers | undefined;
    limit: number;
    rowsPerPageOptions: number[];
    isFetching: boolean;

    handleOnPageChange: (page: number) => void;
    handleFilterChange: (filterModel: GridFilterModel) => void;
    handleOnPageSizeChange: (limit: number) => void;
}

export function UsersTable({
    data,
    limit,
    rowsPerPageOptions,
    isFetching,
    handleOnPageChange,
    handleFilterChange,
    handleOnPageSizeChange,
}: Props) {
    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
        }
    }

    function mapDataToGridRows(data: ListUsers) {
        const { users: users } = data;
        return users.map(user => ({
            id: user.id,
            name: user.name,
            cellphone: user.cellphone,
            email: user.email,
            username: user.username,
            user_type: user.user_type,
            createdAt: new Date(user.created_at).toLocaleString(),
            created_by_name: user.created_by_name,
            license_count: user.license_count,
        }));
    }

    const user = useAppSelector((state: RootState) => state.user.user);
    if (!user) {
        return null; // ou um indicador de carregamento
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            renderCell: renderNameCell,
        },
        {
            field: 'cellphone',
            headerName: 'Celular',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1
        },
        {
            field: 'createdAt',
            headerName: 'Data Criação',
            flex: 1
        },
        {
            field: 'license_count',
            headerName: 'Qtd Licenças',
            flex: 1
        },
    ]

    if (user?.user_type === "ADMIN") {
        columns.push(
            {
                field: 'username',
                headerName: 'Username',
                flex: 1,
                renderCell: renderNameCell,
            },
            {
                field: 'user_type',
                headerName: 'Tipo',
                flex: 1
            },
            {
                field: 'created_by_name',
                headerName: 'Criado Por',
                flex: 1
            }
        );
    }
    // function renderActionsCell(params: GridRenderCellParams) {
    //     return (
    //         <IconButton
    //             color={"secondary"}
    //             onClick={() => handleDeleteUser(params.value)}
    //             aria-label="delete"
    //         >
    //             <DeleteIcon />
    //         </IconButton>
    //     )
    // }

    function renderNameCell(rowData: GridRenderCellParams) {
        return (
            <Link
                style={{ textDecoration: "none" }}
                to={`/users/edit/${rowData.id}`}
            >
                <Typography color="primary">{rowData.value}</Typography>
            </Link>
        )
    }

    const rows: GridRowsProp = data?.users ? mapDataToGridRows(data) : [];
    const rowCount = data?.total || 0;

    return (
        <Box sx={{ display: "flex", height: 671 }}>
            <DataGrid                
                rows={rows}
                pagination={true}
                columns={columns}
                pageSize={limit}
                filterMode="server"
                rowCount={rowCount}
                loading={isFetching}
                paginationMode="server"
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                rowsPerPageOptions={rowsPerPageOptions}
                componentsProps={componentProps}
                onPageChange={handleOnPageChange}
                components={{ Toolbar: GridToolbar }}
                onFilterModelChange={handleFilterChange}
                onPageSizeChange={handleOnPageSizeChange}
            />
        </Box>
    );
}