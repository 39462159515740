import { Box, Button, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material"
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { LoginRequest } from "../../../types/Login";

type Props = {
    login: LoginRequest;
    isDisabled?: boolean;
    isLoading?: boolean;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function LoginForm({
    login,
    isDisabled = false,
    isLoading = false,
    handleSubmit,
    handleChange,
}: Props) {
    return (
        <Box p={2}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >                                            
                        <FormControl fullWidth>
                            <TextField
                                required
                                name="username"
                                label="Usuário"
                                value={login?.username}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>         
                    <Grid item xs={12} >                                            
                        <FormControl fullWidth>                        
                            <TextField                            
                                required
                                name="password"
                                label="Senha"
                                type={"password"}
                                value={login?.password}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>               
                    <Grid item xs={12} >
                        <Box display={"flex"} gap={2}>
                            <Button type="submit" variant="contained" color="secondary" disabled={isDisabled} fullWidth>
                                Entrar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
