import './App.css';
import { ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Layout } from './components/Layout';
import { Routes, Route, Navigate } from 'react-router-dom';
import { appTheme, darkTheme } from './config/theme';
import { UserList } from './features/users/ListUser';
import { UserCreate } from './features/users/CreateUser';
import { UserEdit } from './features/users/EditUser';
import { Login } from './features/login/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { useState, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { useAppDispatch } from './app/hooks';
import { fetchUserData } from './store/loginUserSlice';
import { LicenseList } from './features/licenses/ListLicenses';
import { PaymentList } from './features/payments/ListPayments';
import { UserLicensesPrice } from './features/users/UserLicensePrice';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("access_token"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (token) {
      setIsLoggedIn(true);
      dispatch(fetchUserData());  // Carrega o usuário apenas uma vez
    }

    const handleStorageChange = () => {
      const updatedToken = !!localStorage.getItem("access_token");
      setIsLoggedIn(updatedToken);

      // Se o token mudou (por exemplo, logout em outra aba), limpar o usuário
      if (!updatedToken) {
        dispatch({ type: 'user/clearUser' });  // ou qualquer ação que limpe o usuário
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={darkTheme}>
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          component="main"
          sx={{
            height: '100vh',
            backgroundColor: (theme) => theme.palette.grey[900]
          }}>

          <Routes>
            <Route path="/" element={isLoggedIn ? <Navigate to="/users" /> : <Login />} />

            <Route element={<ProtectedRoute />}>
              <Route element={<Layout />}>
                <Route path="/users" element={<UserList />} />
                <Route path="/users/create" element={<UserCreate />} />
                <Route path="/users/edit/:id" element={<UserEdit />} />
                <Route path="/licenses" element={<LicenseList />} />
                <Route path="/payments" element={<PaymentList />} />
                <Route path="/users/:id/license-price" element={<UserLicensesPrice />} />

                <Route path="*" element={
                  <Box sx={{ m: 2 }}>
                    <Typography variant="h1">404</Typography>
                    <Typography variant="h2">Page not found</Typography>
                  </Box>
                }
                />
              </Route>
            </Route>
          </Routes>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App;
