import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { logout } from "../../store/loginUserSlice";

export const baseUrl = "https://api.cpautobot.com/api";
// export const baseUrl = "http://localhost:8080/api";

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // Tente renovar o token se receber um erro 401
        const newAccessToken = await refreshAccessToken();

        if (newAccessToken) {
            // Armazene o novo token e repita a requisição original com o novo token
            result = await baseQuery(args, api, extraOptions);
        } else {
            // Se o refresh token também falhar, deslogue o usuário
            api.dispatch(logout());
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
        }
    }

    return result;
};

export async function refreshAccessToken() {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return null;

    try {
        const response = await fetch(`${baseUrl}/v1/users/refresh`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh_token: refreshToken }),
        });

        if (!response.ok) {
            throw new Error("Falha ao renovar o token.");
        }

        const data = await response.json();
        const newAccessToken = data.access_token;
        const newRefreshToken = data.refresh_token;

        // Armazena o novo access_token no localStorage
        localStorage.setItem("access_token", newAccessToken);
        localStorage.setItem("refresh_token", newRefreshToken);

        return newAccessToken;
    } catch (error) {
        console.error("Erro ao renovar o token:", error);
        return null;
    }
}

export const apiSlice = createApi({
    reducerPath: "api",
    tagTypes: ["Users", "Login", "Licenses", "Payments", "UserLicensePrice"],
    endpoints: (builder) => ({}),
    baseQuery: baseQueryWithReauth,
})