import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../features/api/apiSlice";
import { User } from "../types/User";

interface UserState {
    user: User | null;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
}

const initialState: UserState = {
    user: null,
    status: "idle",
    error: null,
};

// Thunk assíncrono para buscar dados do usuário
export const fetchUserData = createAsyncThunk("user/fetchUserData", async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("access_token");
    if (!token) return rejectWithValue("Token não encontrado");

    try {
        const response = await fetch(`${baseUrl}/v1/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Erro ao buscar dados do usuário");
        }

        return await response.json();
    } catch (error) {
        return rejectWithValue("Erro ao buscar dados do usuário");
    }
});

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
            state.status = "succeeded";
        },
        clearUser(state) {
            state.user = null;
            state.status = "idle";
            state.error = null;
        },
        logout(state) {
            state.user = null;
            state.status = "idle";
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            window.location.href = "/"; // Redireciona para a página de login
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    },
});

export const { setUser, clearUser, logout } = userSlice.actions;
export default userSlice.reducer;