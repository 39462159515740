import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks";
import { useGetUsersQuery } from "./userSlice";
import { GridFilterModel } from "@mui/x-data-grid";
import { UsersTable } from "./components/UserTable";
import { useState } from "react";


export const UserList = () => {
    const [options, setOptions] = useState({
        page: 1,
        filter: "",
        limit: 10,
        rowsPerPage: [10, 20, 30],
    });

    const { data, isFetching, error } = useGetUsersQuery(options)

    function handleOnPageChange(page: number) {
        setOptions({ ...options, page: page + 1 });
    }

    function handleOnPageSizeChange(limit: number) {
        setOptions({ ...options, limit });
    }

    function handleFilterChange(filterModel: GridFilterModel) {
        if (!filterModel.quickFilterValues?.length) {
            return setOptions({ ...options, filter: "" });
        }

        const filter = filterModel.quickFilterValues.join(" ");
        setOptions({ ...options, filter });
    }

    return (
        <Box maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/users/create"
                    style={{ marginBottom: "1rem" }}
                >
                    Novo Cliente
                </Button>
            </Box>

            <UsersTable
                data={data}
                isFetching={isFetching}
                limit={options.limit}
                rowsPerPageOptions={options.rowsPerPage}
                handleOnPageChange={handleOnPageChange}
                handleOnPageSizeChange={handleOnPageSizeChange}
                handleFilterChange={handleFilterChange}
            />
        </Box>
    );
}