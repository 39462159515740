import { Box, Paper, Typography, SelectChangeEvent, Button } from "@mui/material"
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { UserForm } from "./components/UserForm";
import { useSnackbar } from "notistack";
import { User } from "../../types/User";
import { useGetUserQuery, useUpdateUserMutation } from "./userSlice";
import { UserLicenseForm } from "./components/UserLicenseForm";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

export const UserEdit = () => {
    const id = useParams().id || "";
    const { data: user, isFetching } = useGetUserQuery({ id });
    const [updateUser, status] = useUpdateUserMutation();
    const [userState, setUserState] = useState<User>({
        id: "",
        username: "",
        name: "",
        email: "",
        active: true,
        cellphone: "",
        user_type: "",
        created_at: new Date(),
        license_count: 0,
        created_by_name: "",
        licenses: [],
    });

    const { enqueueSnackbar } = useSnackbar();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await updateUser(userState);
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserState({ ...userState, [name]: value });
    };
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setUserState({ ...userState, [name]: checked });
    };

    const handleChangeUserType = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        console.log(name, value);
        setUserState((prevUser) => ({
            ...prevUser,
            [name]: value, // Atualiza user.user_type
        }));
    };

    useEffect(() => {
        if (user) {
            setUserState(user);
        }
    }, [user]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("User updated successfully", { variant: "success" });
        }

        if (status.isError) {
            enqueueSnackbar("Failed to update user", { variant: "error" });
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess])


    const userLogado = useAppSelector((state: RootState) => state.user.user);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="h4">Atualizar Cliente</Typography>
                        {userLogado?.user_type === "ADMIN" && userState.user_type === "AFFILIATE" &&
                            (
                                <Button
                                    color="secondary"
                                    component={Link}
                                    to={`/users/${userState.id}/license-price`}
                                >
                                    Preço da Licença
                                </Button>
                            )
                        }
                    </Box>
                </Box>
                <UserForm
                    user={userState}
                    isDisabled={status.isLoading}
                    isLoading={false}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleToggle={handleToggle}
                    handleChangeUserType={handleChangeUserType}
                />



                <UserLicenseForm
                    user={userState}
                />
            </Paper>
        </Box>
    );
}