import { CreateLicenseRequest, CreateLicenseResponse, ListLicensesParams, ListLicensesResponse, RenewLicenseRequest, RenewLicenseResponse, ResetLicenseRequest } from "../../types/License";
import { apiSlice } from "../api/apiSlice";

const endpointUrl = "/v1/users/licenses"

function parseQueryParams(params: ListLicensesParams) {
    const query = new URLSearchParams();

    if (params.page) {
        query.append("page", params.page.toString());
    }

    if (params.limit) {
        query.append("limit", params.limit.toString());
    }

    if (params.filter) {
        query.append("filter", params.filter);
    }

    if (params.sort_by) {
        query.append("sort_by", params.sort_by);
    }

    if (params.sort_dir) {
        query.append("sort_dir", params.sort_dir);
    }

    if (params.license_status) {
        query.append("license_status", params.license_status);
    }

    return query.toString();
}

function getLicenses({ page = 1, limit = 10, filter = "", sortBy = "created_at", sortDir = "desc", license_status = "" }) {
    if (license_status === "TODOS") {
        license_status = "";
    }
    const params = { page, limit, filter, sort_by: sortBy, sort_dir: sortDir, license_status };
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createLicenseMutation({ userId, requestBody }: { userId: string, requestBody: CreateLicenseRequest }) {
    return {
        url: `/v1/users/${userId}/licenses`,
        method: "POST",
        body: requestBody,
    };
}

function renewLicenseMutation(requestBody: RenewLicenseRequest) {
    return {
        url: `/v1/users/licenses/renew`,
        method: "post",
        body: requestBody,
    }
}

function resetLicenseMutation(requestBody: ResetLicenseRequest) {
    return {
        url: `/v1/users/licenses/reset`,
        method: "post",
        body: requestBody,
    }
}

export const licenseApiSlice = apiSlice.injectEndpoints({
    endpoints: ({ query, mutation }) => ({
        getLicenses: query<ListLicensesResponse, ListLicensesParams>({
            query: getLicenses,
            providesTags: ["Users", "Licenses"],
        }),
        // getLicense: query<User, { id: number }>({
        //     query: getUser,
        //     providesTags: ["Users"],
        // }),
        createLicense: mutation<CreateLicenseResponse, { userId: string, requestBody: CreateLicenseRequest }>({
            query: createLicenseMutation,
            invalidatesTags: ["Users", "Licenses", "Payments"],
        }),
        renewLicense: mutation<RenewLicenseResponse, RenewLicenseRequest>({
            query: renewLicenseMutation,
            invalidatesTags: ["Users", "Licenses", "Payments"],
        }),
        resetLicense: mutation<void, ResetLicenseRequest>({
            query: resetLicenseMutation,
            invalidatesTags: ["Users", "Licenses"],
        }),
    })
});


export const { useCreateLicenseMutation, useRenewLicenseMutation, useGetLicensesQuery, useResetLicenseMutation } = licenseApiSlice 