import { Box, Button, FormControl, Grid, IconButton, TextField, MenuItem, Select, InputLabel, SelectChangeEvent, Typography } from "@mui/material"
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { User } from "../../../types/User";
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from 'react'
import { useCreateLicenseMutation } from "../../licenses/licenseSlice";
import { useState } from "react"
import { CreateLicenseRequest, License } from "../../../types/License";


type Props = {
    user: User;
    isDisabled?: boolean;
    isLoading?: boolean;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeUserType: (e: SelectChangeEvent<string>) => void;
}

export function UserForm({
    user,
    isDisabled = false,
    isLoading = false,
    handleSubmit,
    handleChange,
    handleToggle,
    handleChangeUserType,
}: Props) {
    const userLogado = useAppSelector((state: RootState) => state.user.user);

    return (
        <Box p={2}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <TextField
                                required
                                name="name"
                                label="Nome"
                                value={user?.name}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <TextField
                                name="cellphone"
                                label="Celular"
                                value={user?.cellphone}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <TextField
                                name="email"
                                label="E-mail"
                                value={user?.email}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    {userLogado?.user_type === "ADMIN" && (
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <InputLabel id="user_type">Tipo de Usuário</InputLabel>
                                <Select
                                    name="user_type"
                                    labelId="user_type"
                                    id="user_type"
                                    value={user?.user_type || ""}
                                    disabled={isDisabled}
                                    label="Tipo de Usuário"
                                    onChange={(e) => handleChangeUserType(e)}
                                >
                                    <MenuItem value={"CUSTOMER"}>Cliente</MenuItem>
                                    <MenuItem value={"AFFILIATE"}>Afiliado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {userLogado?.user_type === "ADMIN" && (
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <TextField
                                    name="username"
                                    label="Login"
                                    value={user?.username}
                                    disabled={isDisabled}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    )}


                    {/* <Grid item xs={12} >
                        <FormControl fullWidth>
                            <TextField
                                required
                                name="description"
                                label="Description"
                                value={user?.description}
                                disabled={isDisabled}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid> */}

                    {/* <Grid item xs={12} >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="is_active"
                                        color="secondary"
                                        onChange={handleToggle}
                                        checked={user?.is_active}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                }
                                label="Active"
                            />
                        </FormGroup>
                    </Grid> */}

                    <Grid item xs={12} >
                        <Box display={"flex"} gap={2}>
                            <Button variant="contained" component={Link} to="/users">
                                Voltar
                            </Button>

                            <Button type="submit" variant="contained" color="secondary" disabled={isDisabled}>
                                Salvar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
