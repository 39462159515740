import axios from "axios";
import { LoginRequest, LoginResponse } from "../../types/Login";
import { User } from "../../types/User";
import { apiSlice, baseUrl } from "../api/apiSlice";

const endpointUrl = "/v1/users/login"


export async function fetchUserData(): Promise<User | null> {
    try {
        const token = localStorage.getItem("access_token");

        // Faz a requisição GET com o token
        const response = await axios.get<User>(`${baseUrl}/v1/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
        return null;
    }
}


function loginMutation(login: LoginRequest) {
    return {
        url: endpointUrl,
        method: "POST",
        body: login,
    }
}

export const loginApiSlice = apiSlice.injectEndpoints({
    endpoints: ({ query, mutation }) => ({
        loginUser: mutation<LoginResponse, LoginRequest>({
            query: loginMutation,
            invalidatesTags: ["Login"],
        }),
        getMe: query<User, void>({
            query: () => ({
                url: "/v1/users/me",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Adiciona o token ao header
                },
            }),
            providesTags: ["Login"],
        }),
    })
});


export const { useLoginUserMutation, useGetMeQuery } = loginApiSlice 