import { Box, Paper, Typography } from "@mui/material"
import { useLoginUserMutation } from "./loginSlice";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { LoginForm } from "./components/LoginForm";
import { LoginRequest } from "../../types/Login";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { fetchUserData } from "../../store/loginUserSlice";

export const Login = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loginUser, status] = useLoginUserMutation()
    const [isDisabled, setIsDisabled] = useState(false)
    const [loginState, setLoginState] = useState<LoginRequest>({
        username: "",
        password: "",
    })

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await loginUser(loginState);
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginState({ ...loginState, [name]: value });
    };
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setLoginState({ ...loginState, [name]: checked });
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (status.isLoading) {
            setIsDisabled(true);  // Desabilita o formulário enquanto está carregando
        } else {
            setIsDisabled(false);
        }

        if (status.isSuccess) {
            const { access_token, refresh_token } = status.data;

            // Armazena os tokens no localStorage
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("refresh_token", refresh_token);

            enqueueSnackbar("Login feito com sucesso", { variant: "success" });
            setIsDisabled(true);

            // Carrega os dados do usuário antes de redirecionar
            // setLoadingUserData(true); // Define o estado de carregamento

            dispatch(fetchUserData());
            navigate("/users"); // Redireciona após garantir que os dados do usuário foram carregados
        }

        if (status.isError) {
            enqueueSnackbar("Usuario ou senha incorretos", { variant: "error" });
        }
    }, [enqueueSnackbar, status.isLoading, status.isSuccess, status.isError, navigate]);


    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="95vh">
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="h4">CPAuto BOT Painel</Typography>
                    </Box>
                </Box>

                <LoginForm
                    login={loginState}
                    isDisabled={isDisabled}
                    isLoading={status.isLoading}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleToggle={handleToggle}
                />
            </Paper>
        </Box>
    );
}