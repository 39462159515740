import { AppBar, Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useAppTheme } from "../hooks/useAppTheme";
import { Header } from "./Header";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { Outlet } from "react-router-dom";
import { darkTheme } from "../config/theme";

const drawerWidth = 240;

// export function Layout({ children }: { children: React.ReactNode }) {
export function Layout() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [currentTheme, toggleCurrentTheme] = useAppTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Box sx={{ display: "flex", height: "100vh", }}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}
                >
                    <Header
                        handleDrawerToggle={handleDrawerToggle}
                        toggle={toggleCurrentTheme}
                        theme={currentTheme.palette.mode === "dark" ? "dark" : "light"}
                    />
                </AppBar>

                <ResponsiveDrawer open={mobileOpen} onClose={handleDrawerToggle} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        mt: "64px", // Compensa a altura do AppBar
                        overflowY: "auto", // Habilita rolagem no canto direito da tela
                        pr: 2, // Margem direita para evitar sobreposição da barra de rolagem
                    }}
                >
                    <Container maxWidth="lg" sx={{ color: "white" }}>
                        <Outlet />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}