import { CreatePaymentRequest, CreatePaymentResponse, ListPaymentParams, ListPaymentResponse } from "../../types/Payment";
import { apiSlice } from "../api/apiSlice";

const endpointUrl = "/v1/payments"

function parseQueryParams(params: ListPaymentParams) {
    const query = new URLSearchParams();

    if (params.page) {
        query.append("page", params.page.toString());
    }

    if (params.limit) {
        query.append("limit", params.limit.toString());
    }

    if (params.filter) {
        query.append("filter", params.filter);
    }

    if (params.sort_by) {
        query.append("sort_by", params.sort_by);
    }

    if (params.sort_dir) {
        query.append("sort_dir", params.sort_dir);
    }

    return query.toString();
}

function getPayments({ page = 1, limit = 10, filter = "", sortBy = "created_at", sortDir = "desc" }) {
    const params = { page, limit, filter, sort_by: sortBy, sort_dir: sortDir };
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function getPaymentById(id: string) {
    return `${endpointUrl}/${id}`;
}

function createPaymentMutation(requestBody: CreatePaymentRequest) {
    return {
        url: endpointUrl,
        method: "POST",
        body: requestBody,
    };
}

function approvePaymentMutation(paymentId: string) {
    return {
        url: `/v1/payments/${paymentId}/approve`,
        method: "POST",
    };
}

function rejectPaymentMutation(paymentId: string) {
    return {
        url: `/v1/payments/${paymentId}/reject`,
        method: "POST",
    };
}


export const licenseApiSlice = apiSlice.injectEndpoints({
    endpoints: ({ query, mutation }) => ({
        getPayments: query<ListPaymentResponse, ListPaymentParams>({
            query: getPayments,
            providesTags: ["Payments"],
        }),
        getPaymentById: query<ListPaymentResponse, String>({
            query: getPaymentById,
            providesTags: ["Payments"],
        }),
        createPayment: mutation<CreatePaymentResponse, CreatePaymentRequest>({
            query: createPaymentMutation,
            invalidatesTags: ["Payments", "Licenses"],
        }),
        approvePayment: mutation<void, string>({
            query: approvePaymentMutation,
            invalidatesTags: ["Payments", "Licenses"],
        }),
        rejectPayment: mutation<void, string>({
            query: rejectPaymentMutation,
            invalidatesTags: ["Payments", "Licenses"],
        })
    })
});


export const { useCreatePaymentMutation, useGetPaymentsQuery, useApprovePaymentMutation, useRejectPaymentMutation } = licenseApiSlice 