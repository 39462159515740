import { Box, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material"
import { DataGrid, GridColDef, GridFilterModel, GridRowsProp, GridSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { useApprovePaymentMutation, useGetPaymentsQuery, useRejectPaymentMutation } from "./paymentSlice";
import { useState, useEffect } from 'react'
import { Payment } from "../../types/Payment";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useSnackbar } from "notistack";


export const PaymentList = () => {
    const [options, setOptions] = useState({
        page: 1,
        filter: "",
        limit: 10,
        rowsPerPage: [10, 20, 30],
        license_status: "TODOS",
    });

    const { enqueueSnackbar } = useSnackbar();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const { data, isFetching, error } = useGetPaymentsQuery(options)
    const [approvePayment, statusApprovePayment] = useApprovePaymentMutation()
    const [rejectPayment, statusRejectPayment] = useRejectPaymentMutation()

    function handleOnPageChange(page: number) {
        setOptions({ ...options, page: page + 1 });
    }

    function handleOnPageSizeChange(limit: number) {
        setOptions({ ...options, limit });
    }

    function handleFilterChange(filterModel: GridFilterModel) {
        if (!filterModel.quickFilterValues?.length) {
            return setOptions({ ...options, filter: "" });
        }

        const filter = filterModel.quickFilterValues.join("");
        setOptions({ ...options, filter });
    }

    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
        }
    }

    const isInvalidDate = (date: string | Date | null) => {
        if (!date) return true;
        const parsedDate = typeof date === "string" ? new Date(date) : date;
        return parsedDate.getTime() === new Date("0001-01-01T00:00:00Z").getTime();
    };

    function mapLicensesToGridRows(payments: Payment[]) {
        return payments.map(payment => ({
            id: payment.id,
            value: payment.value,
            payment_status: payment.payment_status,
            created_at: new Date(payment.created_at).toLocaleString(),
            created_by_name: payment.created_by_name,
        }));
    }

    // function renderOwnerNameCell(rowData: GridRenderCellParams) {
    //     const ownerId = rowData.row.owner_id;

    //     return (
    //         <Link
    //             style={{ textDecoration: "none" }}
    //             to={`/users/edit/${ownerId}`}
    //         >
    //             <Typography color="primary">{rowData.value}</Typography>
    //         </Link>
    //     )
    // }

    const columns: GridColDef[] = [
        {
            field: 'created_at',
            headerName: 'Data Criação',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'value',
            headerName: 'Valor Total',
            flex: 2,
            minWidth: 200,
        },
        {
            field: 'payment_status',
            headerName: 'Status',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'created_by_name',
            headerName: 'Criado Por',
            flex: 1,
            minWidth: 100,
        },
    ]

    const rows: GridRowsProp = data?.payments ? mapLicensesToGridRows(data.payments) : [];
    const rowCount = data?.total || 0;
    const userLogado = useAppSelector((state: RootState) => state.user.user);


    const [open, setOpen] = useState(false);
    const [action, setAction] = useState("");

    const handleClickOpen = (selectedAction: string) => {
        setAction(selectedAction);
        setOpen(true);
    };

    const handleClose = async (confirmed: boolean) => {
        setOpen(false);
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        if (selectedRows.length === 0) {
            enqueueSnackbar("Nenhuma licença selecionada", { variant: "warning" });
            return;
        }

        const licenseIds = selectedRows.map((row) => row.id);

        if (confirmed) {
            if (action === "aprovar") {
                await Promise.all(licenseIds.map((id) => approvePayment(id)));
            } else if (action === "recusar") {
                await Promise.all(licenseIds.map((id) => rejectPayment(id)));
            }
        }
    };

    useEffect(() => {
        if (statusApprovePayment.isSuccess) {
            enqueueSnackbar("Pagamento aprovado com sucesso!", { variant: "success" });
        }

        if (statusRejectPayment.isSuccess) {
            enqueueSnackbar("Pagamento recusado com sucesso!", { variant: "success" });
        }

        if (statusApprovePayment.isError) {
            enqueueSnackbar("Erro ao aprovar pagamento", { variant: "error" });
        }

        if (statusRejectPayment.isError) {
            enqueueSnackbar("Erro ao recusar pagamento", { variant: "error" });
        }
    }, [enqueueSnackbar, statusApprovePayment.isSuccess, statusApprovePayment.isError, statusRejectPayment.isSuccess, statusRejectPayment.isError]);

    return (
        <Box maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Dialog open={open} onClose={() => handleClose(false)}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Tem certeza de que deseja ${action === "aprovar" ? "aprovar" : "recusar"} este pagamento?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleClose(true)} color="secondary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {userLogado?.user_type === "ADMIN" && (
                <Grid container alignItems="center" justifyContent="end" spacing={2} mb={2} mt={1}>
                    <Grid item xs={12} md="auto" >
                        <Box display="flex" gap={2}>
                            <Button type="button" variant="contained" color="secondary" onClick={() => handleClickOpen("recusar")}>Recusar</Button>
                            <Button type="button" variant="contained" color="success" onClick={() => handleClickOpen("aprovar")}>Aprovar</Button>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Box sx={{ display: "flex", height: 671 }}>
                <DataGrid
                    rows={rows}
                    pagination={true}
                    columns={columns}
                    pageSize={options.limit}
                    filterMode="server"
                    rowCount={rowCount}
                    loading={isFetching}
                    paginationMode="server"
                    checkboxSelection={userLogado?.user_type === "ADMIN"}
                    disableColumnFilter={true}
                    disableColumnSelector={false}
                    disableDensitySelector={true}
                    rowsPerPageOptions={options.rowsPerPage}
                    componentsProps={componentProps}
                    onPageChange={handleOnPageChange}
                    components={{ Toolbar: GridToolbar }}
                    onFilterModelChange={handleFilterChange}
                    onPageSizeChange={handleOnPageSizeChange}
                    onSelectionModelChange={(newSelection) => {
                        setSelectionModel(newSelection); // Atualiza o modelo de seleção
                    }}
                />
            </Box>
        </Box>
    );
}